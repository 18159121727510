// src/PhotoWall.js

import React, { useEffect, useState, useRef } from 'react';
import './PhotoWall.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import AMapComponent from '../component/AMapComponent';
import {JK} from '../global'

const photos = [
  'photo1.jpg',
  'photo2.jpg',
  'photo3.jpg',
  'photo4.jpg',
  'photo5.jpg',
  'photo6.jpg',
];

const PhotoWall = () => {
  const [photoIndex, setPhotoIndex] = useState(0); 
  const [messageIndex, setMessageIndex] = useState(0);
  const [messages] = useState([
    "欢迎您加入江口水镇这个温馨的大家庭！这里是我们的网上家园，很高兴能够与您分享这片美丽而宁静的社区。这里不仅有美丽的自然风光，还有友好和谐的邻里关系。无论您是刚搬进来的新邻居，还是已经在此安居的老朋友，我们都衷心希望您在这里能够享受到最舒适和愉悦的生活。",
    "江口水镇，依山傍水，风景如画，希望能够带您更好地了解和感受这个美丽的地方。这里有宽敞的绿地、公园和湖泊，是您与家人散步、休闲的理想场所。设有多种便利设施和服务，以满足您的日常生活需求，让您的生活更加便捷和舒适。", 
    "作为江口水镇的一员，您不仅拥有一个舒适的居所，更可以享受社区带来的多样活动和社交机会。业主举办的各种社区活动可以在这里发布，旨在增进邻里之间的交流和友谊。无论是家庭聚会、节日庆典，还是社区义工活动，您都将有机会参与其中，结识更多的新朋友，丰富您的生活。",
    "再次欢迎您加入江口水镇！我们期待与您共同创建一个更加美好、和谐的社区。期待与您成为朋友！祝您生活愉快，万事如意！"
  ]);
  const timerRef = useRef(0);

  const tips = [
    {title: "房产证办理", content: "房产证代办地点在售楼部，买房时签合同的地方。办理需要个人身份证，购房合同。需缴纳契税及大修基金等，90平以下房屋契税1%，以上1.5%，代办费用三百多。带上本人开户的银行卡，房屋面积退差价。"},
    {title: "装修办理", content: "小区物业处办理装修手续并缴纳押金及装修垃圾费用，需要业主提供装修图纸。装修图纸审核通过（大约3天）、装前验收合格（入户门全包、电梯至门口地面保护、灭火器等）后开通水电。\n物业电话：028-37649804"},
    {title: "天然气开通", content: "天然气需要在热水器燃气灶安装完成后开通。需要向天气燃气公司预约两次，一次验收条件（是否有警报器、厨房是否有门、燃气管走线距离灶具距离是否合格等），一次安装天然器管道。\n燃气公司电话：028-37685968"},
    {title: "本站域名", content: "\nhttps://江口水镇.cn/\nhttps://xn--6oru97cwd885o.cn/ \nhttps://jiangkoushuizhen.nibelungen.cn/"},
  ];

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setPhotoIndex( preIndex => (preIndex + 1) % photos.length);
      setMessageIndex(preIndex => (preIndex + 1) % messages.length);

      const content = document.getElementById('content');
      if (content) {
        content.classList.remove('slide-in'); 
        void content.offsetWidth;
        content.classList.add('slide-in');
      }
    }, 7000);

    return () => {
        clearInterval(timerRef.current);
    };
  }, []);

  return (
    <div className='container'>
      <div className='dynamic-area'>
        <div className='image-section'>
          <img className="photo-item img-fluid fade-in"  src={`/photos/${photos[photoIndex]}`} alt={`photo ${photoIndex}`} />
        </div>
        <div className='text-section'>
              <h3>亲爱的邻居们：</h3>
              <div id='content' className="slide-in">
              <p>
                {messages[messageIndex]}
              </p>
            </div>
        </div>
      </div>
      <div className='tip-box'>
        {tips.map((tip) => (
          <div className='tip'>
            <h2>{tip.title}</h2>
            <p>{tip.content}</p>
          </div>
        ))}
      </div>
      <div>
        <AMapComponent state={0}/>
      </div>

    </div>
  );
};

export default PhotoWall;
